<template>
	<div>
		<section class="section is-main-section">
			<finance-table
				ref="table"
				:settings="table.settings"
				:filter="table.filter"
				:page="table.page" />
		</section>
	</div>
</template>

<script>
	import ClientsTableSample from "@/components/ClientsTableSample"
	import CardComponent from "@/components/CardComponent"
	import RemoveModal from "@/components/RemoveModal"
	import CreateOrUpdate from "./CreateOrUpdate"
	import { RoleService as Service } from "@/services"

	export default {
		name: "RoleList",
		components: {
			CardComponent,
			ClientsTableSample,
		},
		data() {
			return {
				table: {
					filter: [
						{
							name: "Rol",
							type: "input",
							field: "name",
						},
					],
					page: {
						title: "Rol Listesi",
						icon: "bank",
						actions: [
							{
								title: "Rol Oluştur",
								type: "success",
								icon: "plus",
								perm: "Role.Create",
								action: () => {
									this.openModal({
										component: CreateOrUpdate,
										props: { modal: true },
										close: this.$refs.table.loadAsyncData,
									})
								},
							},
							{
								title: "Filtrele",
								type: "info",
								icon: "sync",
								action: () => {
									this.$refs.table.loadAsyncData()
								},
							},
						],
					},
					settings: {
						service: Service,
						method: "list",
						columns: [
							{
								field: "name",
								label: "Rol Adı",
								sortable: true,
							},
							{
								field: "creator.username",
								label: "Oluşturan",
							},
							{
								field: "createdAt",
								label: "Kayıt T.",
								sortable: true,
								viewMethod: (val) =>
									this.dateFormat(
										val,
										this.dateFormats.normalDate
									),
							},
							{
								field: "actions",
								column: "id",
								label: "İşlemler",
								sortable: false,
								width: "100px",
								actions: [
									{
										title: "Düzenle",
										type: "success",
										column: "row",
										action: (data) =>
											this.$router.push({
												name: "roleCreateOrUpdate",
												params: { id: data.uuid },
											}),
									},
									{
										column: "row",
										title: "Sil",
										type: "danger",
										perm: "Role.Delete",
										action: (data) => {
											this.openModal({
												component: RemoveModal,
												props: {
													id: data.uuid,
													name: data.name,
													service: data.service,
													type: "Rol",
												},
												close: this.$refs.table
													.loadAsyncData,
											})
										},
									},
								],
							},
						],
					},
				},
			}
		},
	}
</script>
